import { IThemeOptions } from '@trader/themes';

export const lightTheme: IThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      light: '#EDF9C2',
      main: '#8959E7',
      dark: '#212121',
      100: '#F8F8F8',
    },
    blue: {
      light: '#F1F8FF',
      main: '#005C97',
      dark: '#005C97',
      100: '#798CA4',
      200: '#6983E8',
      300: '#2B78AD',
      400: 'rgba(108, 115, 144, 1)',
      500: 'rgba(66, 69, 86, 1)',
    },
    gray: {
      light: '#F1F1F1',
      main: '#CECECE',
      dark: '#999999',
      100: '#B5B5B5',
      200: '#DBDBDB',
      300: '#F1F1F1',
      400: '#878787',
      500: '#F6F6F6',
      600: '#FBFBFB',
      700: '#848484',
      800: '#A8AAB5',
    },
    green: {
      light: '#EDF9C2',
      main: '#B8DD28',
      dark: '#26A69A',
      100: '#B8DD28',
      200: '#CCEBE8',
      300: '#F5FFFA',
      400: '#26B573',
      500: '#AFF0D2',
      600: '#E3FAEF',
    },
    red: {
      light: '#FF5050',
      main: '#F73F4A',
      dark: '#F73F4A',
      100: 'rgba(231,88,88,0.47)',
      200: '#F7DFE1',
    },
    yellow: {
      light: '#F9EAD7',
      main: '#F7931A',
      dark: '#ab630e',
      100: '#FEDF3E',
    },
    aqua: {
      light: '#DBE3EB',
      main: '#DBE3EB',
      dark: '#DBE3EB',
      100: '#E8E8E8',
      200: '#E4F4FF',
    },
    text: {
      primary: '#212121',
      secondary: '#212121',
      disabled: '#9B9B9B',
    },
    white: {
      main: '#fff',
    },
    black: {
      main: '#212121',
      light: '#131522',
      100: '#F1F1F1',
    },
    common: {
      black: '#212121',
      white: '#FFF',
    },
    background: {
      default: '#e5e8ec',
      paper: '#fff',
    },
    menu: {
      main: '#FFF',
    },
    chartMenu: {
      main: '#112211',
      disabled: '#BBBBBB',
    },
    tab: {
      main: '#F1F1F1',
      light: '#212121',
      disabled: '#4F5364',
      100: '#D2D8E0',
    },
    purchaseButtons: {
      green: '#26A69A',
      red: '#F73F4A',
    },
    inputField: {
      background: '#F1F1F1',
      border: '#F1F1F1',
      text: '#212121',
      disabledBg: '#F6F6F6',
      disabledText: '#CECECE',
      label: '#212121',
      placeholder: '#878787',
    },
    boxShadow: {
      main: '0px 0px 10px 0px #0000001A',
    },
  },
};
